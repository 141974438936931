import {
  fakeZdData,
  fakeLearningData,
  fakeTemplateData,
} from './fakeResources';

export async function getZdData() {
  try {
    let zdData = fakeZdData;
    if (window.location.host === 'adm.hotjar.com') {
      const rawZdData = await fetch(
        'https://csq-cedo-functions.onrender.com/resources/hc'
      );
      zdData = await rawZdData.json();
    }
    return zdData;
  } catch (error) {
    console.error(error);
  }
}

export async function getLearningData() {
  try {
    let learningData = fakeLearningData;
    if (window.location.host === 'adm.hotjar.com') {
      const rawLearningData = await fetch(
        'https://csq-cedo-functions.onrender.com/resources/skilljar'
      );
      learningData = await rawLearningData.json();
    }
    return learningData;
  } catch (error) {
    console.error(error);
  }
}

export async function getTemplates() {
  try {
    let templateData = fakeTemplateData;
    if (window.location.host === 'adm.hotjar.com') {
      const rawTemplateData = await fetch(
        'https://csq-cedo-functions.onrender.com/resources/templates'
      );
      templateData = await rawTemplateData.json();
    }
    return templateData;
  } catch (error) {
    console.error(error);
  }
}

export async function submitLearnLink(resources, message) {
  try {
    let learnLinkId = '123abc';

    if (window.location.host === 'adm.hotjar.com') {
      const rawLearnLinkId = await fetch(
        'https://csq-cedo-functions.onrender.com/learn-link',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            resources,
            message,
          }),
        }
      );
      learnLinkId = await rawLearnLinkId.json();
    }
    return learnLinkId;
  } catch (error) {
    console.log(error);
  }
}
